import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const ButtonListPattern = ({ fitHeight, className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span
      className={clsx("icon block", _className, {
        "icon--fit-height": fitHeight,
      })}
    >
      <m.svg
        xmlns="http://www.w3.org/2000/svg"
        width="638"
        height="383"
        viewBox="0 0 638 383.5"
        initial="hidden"
        whileInView="visible"
        className="stroke-2 opacity-40 sm:stroke-1 sm:opacity-100"
        viewport={{
          once: true,
        }}
      >
        <m.path
          d="M278.703 352.026H115.677C109.242 352.026 104.006 346.846 103.938 340.411C103.461 295.292 139.905 258.46 185.028 258.46H265.203C278.356 258.46 288.979 269.197 288.84 282.349L288.203 342.627C288.148 347.834 283.911 352.026 278.703 352.026Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M414.564 309.189V235.407C414.564 230.16 418.818 225.907 424.064 225.907H496.094C502.741 225.907 508.131 231.296 508.131 237.944V239.292C508.131 284.431 471.538 321.024 426.399 321.024C419.863 321.024 414.564 315.725 414.564 309.189Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M267.342 311.681V268.418C267.342 220.111 306.503 180.949 354.81 180.949C358.178 180.949 360.908 183.68 360.908 187.047V311.524C360.908 316.77 356.655 321.024 351.408 321.024H276.684C271.525 321.024 267.342 316.841 267.342 311.681Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M448.509 383.034H498.174C546.481 383.034 585.642 343.873 585.642 295.566C585.642 292.198 582.912 289.468 579.545 289.468H455.068C449.822 289.468 445.568 293.721 445.568 298.968V380.093C445.568 381.718 446.885 383.034 448.509 383.034Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M629.852 0.5L631.294 0.5C634.722 0.5 637.5 3.27843 637.5 6.7058V84.5661C637.5 89.8128 633.247 94.0661 628 94.0661L548.481 94.0661C545.114 94.0661 542.384 91.3361 542.384 87.9685C542.384 39.661 581.545 0.5 629.852 0.5Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M578.879 132.5H580.321C583.748 132.5 586.527 135.278 586.527 138.706V216.566C586.527 221.813 582.273 226.066 577.027 226.066H497.508C494.14 226.066 491.41 223.336 491.41 219.968C491.41 171.661 530.571 132.5 578.879 132.5Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M87.9685 383.034H89.4106C92.838 383.034 95.6164 380.256 95.6164 376.828V298.968C95.6164 293.721 91.3631 289.468 86.1164 289.468H6.59764C3.23 289.468 0.5 292.198 0.5 295.566C0.5 343.873 39.661 383.034 87.9685 383.034Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M322.047 320.472H396.613C401.86 320.472 406.113 324.726 406.113 329.972V404.538C406.113 409.785 401.86 414.038 396.613 414.038H322.047C316.8 414.038 312.547 409.785 312.547 404.538V329.972C312.547 324.726 316.8 320.472 322.047 320.472Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M197.199 361.341V404.604C197.199 452.911 158.038 492.072 109.731 492.072C106.363 492.072 103.633 489.342 103.633 485.975L103.633 361.498C103.633 356.251 107.886 351.998 113.133 351.998H187.857C193.016 351.998 197.199 356.181 197.199 361.341Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M541.934 309.972V102.231C541.934 97.7588 545.56 94.1337 550.031 94.1337C597.235 94.1337 635.501 132.4 635.501 179.603V309.972C635.501 315.219 631.247 319.472 626.001 319.472H551.434C546.188 319.472 541.934 315.219 541.934 309.972Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

ButtonListPattern.defaultProps = {};

export default ButtonListPattern;
